import { Component, OnInit } from '@angular/core';
import { faTwitter, faFacebookF, faInstagram, } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  faEnvelope = faEnvelope
  faTwitter = faTwitter;
  faFacebookF = faFacebookF;

  constructor() { }

  ngOnInit(): void {
  }

}
