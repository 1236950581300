<!-- <nav id="navbar" class="navbar navbar-expand-lg mr-auto navbar-static-top liteBg px-0">
    <div class="container pl-0">
        <a class="navbar-brand" href="/main">
            <img class="col-12 logoSize" src="../../../../assets/Imgs/SJCOELogo.svg"
                alt="San Joaquin County of Education">
        </a>
        <button class="navbar-toggler" aria-label="Toggle Nav Bar" type="button"
            (click)="isMenuCollapsed = !isMenuCollapsed">
            &#9776;
        </button>

        <div [ngClass]="{'hide': isMenuCollapsed, 'show': !isMenuCollapsed}" class="collapse navbar-collapse main-nav super-nav">
            <span class="pointer super-x" (click)="isMenuCollapsed = !isMenuCollapsed">
                <p class="xSpot">  <fa-icon [icon]="faTimes" style="font-size: 50px; color: #fff;" alt="Close Menu"></fa-icon></p>
            </span>

            <ul class="navbar-nav ml-auto navBarCenter">
                <ul class="nav-item">
                    <a class="nav-link nav-white pointer" (click)="isMenuCollapsed = !isMenuCollapsed"
                            id="dropdownBasic1"  href="#testing">Environmental
                            Literacy</a>
                </ul>
                <ul class="nav-item">
                    <a class="nav-link" id="resourcesNav" href="#" target="_blank"
                        (click)="isMenuCollapsed = true">Student Events</a>
                </ul>
                <ul class="nav-item">
                    <a class="nav-link nav-white pointer" (click)="isMenuCollapsed = !isMenuCollapsed"
                            id="dropdownBasic3"  href="https://www.sjcoescience.org/">SJCOE
                            Science Home Page</a>
                </ul>
            </ul>
        </div>
    </div>
</nav> -->

<div class="container d-flex text-center justify-content-center px-0 pt-3">
    <nav id="navbar" class="navbar navbar-expand-lg mr-auto navbar-static-top fix-width fix-navbar px-0 liteBg">
        <div class="container pl-0">
             <a class="navbar-brand" href="/"> 
               
                <img class="col-12 logoSize" src="../../../../assets/Imgs/ProDev-Logo2.jpg"
                    alt="SJCOE Home">
             
             </a> 
            <button class="navbar-toggler bg-light" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
                &#9776;
            </button>
            <div [ngClass]="{'hide': isMenuCollapsed, 'show': !isMenuCollapsed}"
                class="super-nav navbar-collapse collapse" style="justify-content: flex-end;">
                <span class="pointer super-x" (click)="isMenuCollapsed = !isMenuCollapsed">
                    <p class="xSpot">  <fa-icon [icon]="faTimes" style="font-size: 50px; color: #fff;" alt="Close Menu"></fa-icon></p>
                </span>
                <ul class="navbar-nav ml-auto">
                    <!-- if using a dropdown add this this a tags => ngbDropdownToggle -->

                    <li class="d-inline-block nav-item space-nav-home">
                        <a class="nav-link nav-white pointer" 
                            id="dropdownBasic1"  href="https://outdooreducation.sjcoescience.org/" target="_blank">Outdoor Education</a>
                    </li>
                  
                    

                    <li class="d-inline-block nav-item space-nav-home">
                        <a class="nav-link nav-white pointer" 
                            id="dropdownBasic2"  href="https://environmentalliteracy.sjcoescience.org" target="_blank">Environmental
                            Literacy</a>
                    </li>

                    <li class="d-inline-block nav-item space-nav-home">
                        <a class="nav-link nav-white pointer" 
                            id="dropdownBasic3" href="https://fablab.sjcoescience.org/" target="_blank">FabLab</a>
                    </li>
             
                    <li class="d-inline-block nav-item space-nav-home">
                        <a target="_blank" class="nav-link nav-white pointer" 
                            id="dropdownBasic4"  href="https://www.sjcoescience.org/">SJCOE
                            STEM Programs</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
