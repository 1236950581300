<div id="events" class="eventCardsRow eventPad card-box col-12 ">
   <div class="container \" style="padding: 0 25px 0 15px;">
   <!-- <div class="row"> -->
      <div class="interested">
        <h3 class="attendText ">What events are you interested in?</h3>
      </div>
      <div class="formIndex filterCont">
        <select [(ngModel)]="selectedValue" (ngModelChange)="handleSelectedCategories(selectedValue)"
          class="form-select" aria-label="Option to filter events by type.">
          <option value="All">All</option>

          <!-- <option *ngIf="this.filterOptPro_Dur" value="Durham Ferry Pro Learning">Durham Ferry Pro Learning</option>
          <option *ngIf="this.filterOptPro_Lit" value="Professional Environmental Literacy">Professional Environmental Literacy</option>
          <option *ngIf="this.filterOptAdult_District" value="Professional Learning">Professional Learning</option>
          
          <option *ngIf="this.filterNGSS" value="NGSS Implementation">NGSS Implementation</option>
          <option *ngIf="this.filterEdtech" value="Educational Technology">Educational Technology</option>
          <option *ngIf="this.filterWorkshop_fab" value="Fablab Workshop">Fablab Workshop</option>
          <option *ngIf="this.filterWorkshop_envlit" value="Environmental Literacy Workshop">Environmental Literacy Workshop</option>
          <option *ngIf="this.filterWorkshop_durham" value="Durham Ferry Workshop">Durham Ferry Workshop</option> -->
          <option *ngIf="this.filterOptPro_Lit" value="Environmental Literacy">Environmental Literacy</option>
          <option *ngIf="this.filterEdtech" value="Educational Technology">Educational Technology</option>
         
          <option *ngIf="this.filterNGSS" value="NGSS Implementation">NGSS Implementation</option>


          <option *ngIf="this.filterWorkshop" value="Workshop">Workshop</option>
          <option *ngIf="this.filterConference" value="Conference">Conference</option>

        </select>
      </div>
   <!--  </div>-->
  </div> 
</div>






<div class="card-box container">
  <div class="cont testClass">


    <div class="fcard" *ngFor="let event of this.filteredEventCards">

    <a class="" *ngIf="event.description_Long != '#' && event.internal_event == true" href="{{ event.internal_event === true ? 'event/' +event.eventId+ '' : event.description_Long}}" >
      <div class="fcard-img-top" [ngStyle]="{'background-image': 'url('+event.images+')'}" alt=""></div>
      <div class="fcard-body" alt="{{event.description_Long}}">
        <h5 class="card-title mt-2">{{event.title_Long}}</h5>
        <div class="card-text" [innerHTML]="event.description_Short"></div>
      </div>
      <div class="fcard-footer ">
        <p class="mb-1 tag">{{event.tags}}</p>
      </div>
    </a>

    <!-- this one is for external links -->
    <a class="" *ngIf="event.description_Long != '#' && event.internal_event == false" href="{{event.description_Long}}" target="_blank">
      <div class="fcard-img-top" [ngStyle]="{'background-image': 'url('+event.images+')'}" alt=""></div>
      <div class="fcard-body" alt="{{event.description_Long}}">
        <h5 class="card-title mt-2">{{event.title_Long}}</h5>
        <div class="card-text" [innerHTML]="event.description_Short"></div>
      </div>
      <div class="fcard-footer ">
        <p class="mb-1 tag">{{event.tags}}</p>
      </div>
    </a>

    <span class="" *ngIf="event.description_Long == '#'" >
      <div class="fcard-img-top" [ngStyle]="{'background-image': 'url('+event.images+')'}" alt=""></div>
      <div class="fcard-body2" alt="{{event.description_Long}}">
        <h5 class="card-title mt-2">{{event.title_Long}}</h5>
        <div class="card-text" [innerHTML]="event.description_Short"></div>
      </div>
      <div class="fcard-footer ">
        <p class="mb-1 tag">{{event.tags}}</p>
      </div>
    </span>
  </div><!-- end ng if -->
  </div>
</div>