import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IEvent } from '../../models/eventdata';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
@Injectable({
  providedIn: 'root'
})
export class EventdataService {

  constructor(private http: HttpClient, private dService: DataService) { }

  getProfessionalEvents(): Promise<IEvent[]> {
    //return this.dService.get("event/allevents").toPromise();
    return this.dService.get("event/ProfessionalEvents").toPromise();
    // return this.dService.get("event/ProDevEvents").toPromise();
  }
}