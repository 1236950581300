<footer id="footer" class="page-footer">
    <div class="container">
        <div class="row pt-5">
            <div class="col-lg-4 footerCol">
                    <h5>Contact</h5>
                    <p><a class="footerLink" href="tel:2094684880">(209) 468-4880</a><br>
                   2707 Transworld Drive. Stockton, CA 95206</p>
                    <a href="https://twitter.com/sjcoestem" target="_blank" class="fab fa-twitter" aria-label="twitter"></a>
		
                    <a href="https://www.facebook.com/SJCOESTEM" target="_blank" class="fab fa-facebook-f iconPad pb-3" aria-label="facebook"></a>
                    
                    <a  href="https://www.instagram.com/sjcoestemprograms/" target="_blank" class="fab fa-instagram"  aria-label="instagram"></a>               
            </div>
            <div class="col-lg-4 footerCol">
                    <h5>About Us</h5>
                    <p>STEM Programs at San Joaquin County Office of Education provides professional learning for
                        teachers and programs and event for students in the areas of science while integrating,
                        technology, engineering, and math and environmental literacy.</p>
            </div>
            <div class="col-lg-4 footerCol" style="padding-bottom:25px;">
                    <h5>Newsletter</h5>
                    <a href="https://visitor.r20.constantcontact.com/manage/optin?v=0018nJcGPmgGojZvChcbv_pDp8yboLT-kceGIkYUsjbLkF2vaMHWB_Xk7wScZBkZno-6StKvOl0pzMvzSLWvBs_PFi4_OmIa6bgREdF-GnMYrM%3D" class="footerLink" target="_blank">Sign up for our newsletter</a>
            </div>
            <div class="footerLogo">
                <div class="container" style="text-align:center;padding-top:25px;">
                    <a href="https://www.sjcoe.org/" target="_blank">
                    <img class="col-12 logoSize" src="../../../../assets/Imgs/SJCOE-Formal.png" alt="San Joaquin County of Education"> 
                </a>
                </div>
                <p class="footerHide" style="font-size: 13px;">​Copyright © SJCOE 2022</p>
            </div>
        </div>   
    </div>
</footer>