import { Component, OnInit, HostListener } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
​import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  faTimes = faTimes;

  constructor(public router:Router) { }

  public isMenuCollapsed = true;
  btnClicked: boolean = true;
  
  ngOnInit(): void {
    console.log(this.isMenuCollapsed);
  }

}
