import { Pipe, PipeTransform } from '@angular/core';
import {IEvent} from '../models/eventdata';
@Pipe({
  name: 'homeFilter'
})
export class HomeFilter implements PipeTransform {

  transform(cards: IEvent[], searchText: string, fieldName: string): any[] {

    // return empty array if array is falsy
    if (!cards) { return []; }

    // return the original array if search text is empty
    if (!searchText) { return cards; }

    // retrun the filtered array
    return cards.filter(item => {
      console.log(item)
      console.log(searchText)
      if (item && item[fieldName]) {
        return item[fieldName].toLowerCase().includes(searchText);
      }
      return false;
    });
   }
}