import { Component, AfterViewInit } from '@angular/core';
import { MapServiceService } from '../services/map-service.service';
import * as L from 'leaflet';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-mobmap',
  templateUrl: './mobmap.component.html',
  styleUrls: ['./mobmap.component.scss']
})
export class MobmapComponent implements AfterViewInit {

  private map2: any;
  private initMap(): void {
    this.map2 = L.map('map2', {
      center: [37.90785584360491, -121.22904715813003],
      zoom: 15
    });
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    tiles.addTo(this.map2);
  }
  constructor(private mapServiceService: MapServiceService) { }
  // constructor() { }


  ngAfterViewInit(): void {
    this.initMap();
    this.mapServiceService.makeCapitalMarkers(this.map2);
  }

}
