import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventCardComponent } from './components/event-card/event-card.component';
import { FooterComponent } from './components/footer/footer.component';
import { Modules, Components } from './module-helper';
import { HeaderComponent } from './components/header/header.component';
import { MapComponent } from './components/map/map.component';
import { MobmapComponent } from './components/mobmap/mobmap.component';
import { HomeFilter } from './pipes/homefilter.pipe';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    EventCardComponent,
    FooterComponent,
    HeaderComponent,
    MapComponent,
    MobmapComponent,
    HomeFilter
  ],
  imports: [
    CommonModule,
    FormsModule,
    Modules
  ],
  exports: [
    Components,
    Modules
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class SharedModule { }