import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IEvent } from '../../models/eventdata';
import { EventdataService } from '../services/eventdata.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {
  defaultEventImg: string;
  selectedModal: any = {}
  selectedValue: string;
  selectedCategories: IEvent[] = [];
  filteredEventCards: IEvent[] = [];
  originalEventCards: IEvent[] = [];

  filterOptAdult_District: boolean = false;
  filterOptPro_Dur: boolean = false;
  filterOptPro_Lit: boolean = false;
  filterWorkshop: boolean = false;

  filterNGSS: boolean = false;
  filterEdtech: boolean = false;
  filterWorkshop_fab: boolean = false;
  filterWorkshop_envlit: boolean = false;
  filterWorkshop_durham: boolean = false;
  filterConference: boolean = false;


  showcasedCards: IEvent[] = [];
  nonshowcasedCards: IEvent[] = [];

  constructor(public router: Router, private sanitizer: DomSanitizer, private _eventservice: EventdataService,) { }
  @Input() eventCard: IEvent;
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  ngOnInit(): void {
    this._eventservice.getProfessionalEvents().then((res: IEvent[]) => {
      var x: IEvent[] = res
      console.log(x)
      console.log(x.filter(y => y.section_conference == true))
      this.originalEventCards = res
      // .filter(event => event.published === true)
      //.filter(event => event.published === true && event.section_Adult_District === true && event.isDeleted === false || event.section_Pro_Dur === true)
      .filter(event => event.published === true && event.isDeleted === false )
      .map(event => {
        event.images = 'https://stemprodstoragewest.blob.core.windows.net/photos/'+event.images.split("|")[0];
        event.tags = event.tags.split(',').join(' • ');
        return event;
      });
      this.showcasedCards = this.originalEventCards.filter(event => event.isShowcased === true);
      this.nonshowcasedCards = this.originalEventCards.filter(event => event.isShowcased === false);
      this.selectedCategories = this.originalEventCards;
      this.filteredEventCards = this.showcasedCards.concat(this.nonshowcasedCards);
      this.removeOptFromFilter();
    });
    this.selectedValue = "All";
    this.handleSelectedCategories(this.selectedValue);
  }

  handleSelectedCategories(values: string) {
    let cardInfo: IEvent[] = [];
    if (this.selectedValue === "Professional Learning") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];

      cardInfo.map(x => x.section_Adult_District === true ? this.filteredEventCards.push(x) : null);
    } else if (this.selectedValue === "Durham Ferry Pro Learning") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];
      cardInfo.map(x => x.section_Pro_Dur === true ? this.filteredEventCards.push(x) : null);
    }
    
    else if (this.selectedValue === "Environmental Literacy") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];
      cardInfo.map(x => x.section_Pro_Lit === true ? this.filteredEventCards.push(x) : null);
    } 
    
    else if (this.selectedValue === "NGSS Implementation") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];
      cardInfo.map(x => x.section_NGSS === true ? this.filteredEventCards.push(x) : null);
    } 
    
    else if (this.selectedValue === "Educational Technology") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];
      cardInfo.map(x => x.section_edtech === true ? this.filteredEventCards.push(x) : null);
    } 

    else if (this.selectedValue === "Workshop") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];
      cardInfo.map(x => x.section_workshop_fab === true || x.section_workshop_envlit === true || x.section_workshop_durham === true ? this.filteredEventCards.push(x) : null);
    } 
    
   /*  else if (this.selectedValue === "Fablab Workshop") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];
      cardInfo.map(x => x.section_workshop_fab === true ? this.filteredEventCards.push(x) : null);
    }  */
    
  /*   else if (this.selectedValue === "Environmental Literacy Workshop") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];
      cardInfo.map(x => x.section_workshop_envlit === true ? this.filteredEventCards.push(x) : null);
    } 
     */
 /*    else if (this.selectedValue === "Durham Ferry Workshop") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];
      cardInfo.map(x => x.section_workshop_durham === true ? this.filteredEventCards.push(x) : null);
    } 
     */
    else if (this.selectedValue === "Conference") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];
      cardInfo.map(x => x.section_conference === true ? this.filteredEventCards.push(x) : null);
    } 
    
    else {
      // this.filteredEventCards = this.selectedCategories;
      this.filteredEventCards = this.showcasedCards.concat(this.nonshowcasedCards);
    }
  }

  removeOptFromFilter() {
    for (let i = 0; i < this.filteredEventCards.length; i++) {
      // Checking for events to flip bool
      if (this.filteredEventCards[i].section_Pro_Dur === true) {
        this.filterOptPro_Dur = true;
      }
      if (this.filteredEventCards[i].section_Pro_Lit === true) {
        this.filterOptPro_Lit = true;
      }
      if (this.filteredEventCards[i].section_Adult_District === true) {
        this.filterOptAdult_District = true;
      }

      if (this.filteredEventCards[i].section_workshop_durham === true || this.filteredEventCards[i].section_workshop_envlit === true || this.filteredEventCards[i].section_workshop_fab === true) {
        this.filterWorkshop = true;
      }




      if (this.filteredEventCards[i].section_NGSS === true) {
        this.filterNGSS = true;
      }





      if (this.filteredEventCards[i].section_edtech === true) {
        this.filterEdtech = true;
      }
      if (this.filteredEventCards[i].section_workshop_fab === true) {
        this.filterWorkshop_fab = true;
      }
      if (this.filteredEventCards[i].section_workshop_envlit === true) {
        this.filterWorkshop_envlit = true;
      }
      if (this.filteredEventCards[i].section_workshop_durham === true) {
        this.filterWorkshop_durham = true;
      }
      if (this.filteredEventCards[i].section_conference === true) {
        this.filterConference = true;
      }

    }

  }
}
//  toEvent(): void{
//     if(this.eventCard.internal_event === true){
//       this.router.navigate(['/event/' + this.eventCard.eventId]);
//     } else if (this.eventCard.internal_event === false){
//       window.location.href = this.eventCard.description_Long;
//       // window.location.href = ('http://' + this.eventCard.description_Long);
//     }
//   } 

