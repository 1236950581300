import { EventCardComponent } from "./components/event-card/event-card.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderComponent } from "./components/header/header.component";
import { MapComponent } from "./components/map/map.component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MobmapComponent } from "./components/mobmap/mobmap.component";
import { HomeFilter } from './pipes/homefilter.pipe';

export const Components = [
    EventCardComponent,
    FooterComponent,
    HeaderComponent,
    MapComponent,
    MobmapComponent,
    HomeFilter
    ];
    
export const Modules = [
    FontAwesomeModule,
    NgbModule
    ];